import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

import { ProjectsQuery } from '../../graphql-types'

const NotFound = ({
  data,
  location,
}: {
  data: ProjectsQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const featuredProjects = get(data, 'allContentfulFeaturedProject.edges')

  return <h1>Not found</h1>
}

export default NotFound

export const pageQuery = graphql`
  query NotFound {
    site {
      siteMetadata {
        title
      }
    }
  }
`
